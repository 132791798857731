import {
  nextGeocode,
  nextPlaceAutocomplete,
  nextPlaceDetails
} from '../NextApi/google'

import {
  getAddressObject,
  getLocation,
  getSuggestOptions,
  Suggest,
  SuggestOptions,
  AddressObject,
  isAllowedSuggestType
} from './Geosuggest.utils'

class Geosuggest {
  /**
   * The autocomplete service to get suggests
   */
  private autocompleteService = nextPlaceAutocomplete

  /**
   * The sessionToken service to use session based monetization
   */
  private sessionToken:
    | google.maps.places.AutocompleteSessionToken
    | undefined = undefined

  /**
   * The geocoder to get geocoded results
   */
  private geocoder = nextGeocode

  /**
   * The places service to get place details
   */
  private placesService = nextPlaceDetails

  constructor() {
    this.sessionToken = Math.random().toString(36)
  }

  getDetails(suggestToGeocode: Suggest): Promise<Suggest> {
    const options: google.maps.places.PlaceDetailsRequest = {
      placeId: suggestToGeocode.placeId || '',
      sessionToken: this.sessionToken
    }

    return new Promise((resolve, reject) => {
      this.placesService(options).then((resp) => {
        if (resp?.status === 'OK') {
          const gmaps = resp?.result || {}
          const location = gmaps.geometry?.location
          const suggest = {
            ...suggestToGeocode,
            gmaps,
            location: getLocation(location)
          }

          resolve(suggest as Suggest)
        } else reject(resp)
      })
    })
  }

  async geocode(
    suggestToGeocode: Suggest,
    suggestOptions: SuggestOptions
  ): Promise<Suggest> {
    const options: google.maps.GeocoderRequest = {
      address: suggestToGeocode.label,
      bounds: suggestOptions.bounds,
      componentRestrictions: suggestOptions.country
        ? { country: suggestOptions.country }
        : undefined,
      location: suggestOptions.location
    }

    return new Promise((resolve, reject) => {
      this.geocoder(options).then((resp) => {
        if (resp?.status === 'OK') {
          const gmaps = resp?.results[0]
          const location = gmaps?.geometry?.location
          const suggest = {
            ...suggestToGeocode,
            gmaps,
            location: getLocation(location)
          }

          resolve(suggest as Suggest)
        } else reject(resp)
      })
    })
  }

  reverseGeocode(latlng: {
    lat: number
    lng: number
  }): Promise<AddressObject> | null {
    if (!this.geocoder) return null

    const latLngString = `${latlng.lat},${latlng.lng}`

    return new Promise((resolve, reject) => {
      this.geocoder({ latlng: latLngString }).then((resp) => {
        if (resp?.status === 'OK') {
          const gmaps = resp?.results[0]
          if (gmaps?.address_components) {
            const address = getAddressObject({
              addressComponents: gmaps?.address_components
            })

            resolve(address)
          } else reject(resp)
        } else reject(resp)
      })
    })
  }

  async geocodeSuggest(
    suggestToGeocode: Suggest,
    suggestOptions: SuggestOptions
  ): Promise<Suggest> {
    if (suggestToGeocode.placeId) {
      return this.getDetails(suggestToGeocode)
    }

    return this.geocode(suggestToGeocode, suggestOptions)
  }

  searchSuggests(
    input: string,
    suggestOptions: SuggestOptions
  ): Promise<Suggest[]> | null {
    if (!this.autocompleteService || !input) return null

    const formattedOptions = getSuggestOptions(suggestOptions)

    const options = {
      input,
      sessionToken: this.sessionToken,
      language: 'en',
      components: 'country:GB',
      ...formattedOptions
    } as google.maps.places.AutocompletionRequest

    return new Promise((resolve, reject) => {
      this.autocompleteService(options).then((result) => {
        if (result.status !== 'OK') reject(result)

        const suggests: Suggest[] = []

        result?.predictions?.forEach((suggest) => {
          const isAllowed = isAllowedSuggestType(suggest)

          if (!isAllowed) return

          suggests.push({
            description: suggest.description,
            label: suggest?.description,
            matchedSubstrings: suggest.matched_substrings[0],
            structuredFormatting: suggest.structured_formatting,
            placeId: suggest.place_id
          })
        })

        resolve(suggests)
      })
    })
  }
}

export default Geosuggest
